import { Timesheet } from '../../model/Timesheet'
import { TimeOff, TimeOffStatus } from '../../model/TimeOff'
import { Employee } from '../../model/Employee'
import { PublicHoliday } from '../../model/PublicHoliday'
import isBetweenPlugin from 'dayjs/plugin/isBetween'

import React, { useState } from 'react'
import { Grid, Skeleton, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import Typography from '@mui/material/Typography'
import { timesheetService } from '../../service/timesheetService'
import { useToast } from '../../hooks/use-toast'
import { defaultErrorHandler } from '../../util/errorHandler'
import { EmployeeCalendar } from './EmployeeCalendar'
import { EmployeeListView } from './EmployeeListView'
import { ValidationConfirmationDialog } from './ValidationConfirmationDialog'

dayjs.extend(isBetweenPlugin)

export const EmployeeTimesheet: React.FC<{
  timesheet: Timesheet[]
  timeOffs: TimeOff[]
  loading: boolean
  employee?: Employee
  publicHolidays: PublicHoliday[]
}> = ({ timesheet, timeOffs, employee, publicHolidays, loading }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const toast = useToast()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const hasPendingTimeOffs = timeOffs.some((timeOff) => timeOff.status === TimeOffStatus.requested && timeOff.date <= new Date())
  const validate = () => {
    setIsSubmitting(true)
    timesheetService.validate(timesheet, timeOffs, publicHolidays)
      .then(() => {
        toast.show('success', 'Timesheet validated')
      })
      .catch((error) => {
        defaultErrorHandler(error, toast)
      }).finally(() => {
        setIsSubmitting(false)
      })
  }

  return (
      <Grid container sx={{ ...(isMobile && { marginTop: 2 }) }} flexGrow={1} spacing={2} justifyContent={isMobile ? 'left' : 'center'}>
        <Grid item {...isMobile && { xs: 12 }} sx={{ marginLeft: isMobile ? 0.5 : 0 }}>
          <EmployeeCalendar employee={employee} timesheet={timesheet} loading={loading} publicHolidays={publicHolidays}
                            timeOffs={timeOffs}/>
          <Typography sx={{ display: window.innerWidth > 600 ? 'block' : 'none' }} width={400} textAlign="center"
                      variant="body2" marginTop={1}>
            {
              timesheet?.length === 0
                ? 'Nothing more to do, your timesheet is validated to date.'
                : 'Ensure your time sheet is accurate and up to date. Check the list on the right and validate it!'
            }
          </Typography>
          <Grid container justifyContent="center">

            <Grid sx={{
              display: window.innerWidth < 600 ? 'none' : 'block'
            }} item>
              <Tooltip title={hasPendingTimeOffs ? 'You have pending time offs' : ''}>
                <span>
               <ValidationConfirmationDialog onSubmit={validate} loading={isSubmitting}
                                             disabled={loading || (timesheet?.length ?? 0) === 0 || hasPendingTimeOffs || isSubmitting}/>
                  </span>
              </Tooltip>
            </Grid>

          </Grid>
        </Grid>
        <Grid item sx={
          {
            ...(isMobile && { xs: 12 }) && {
              paddingBottom: 10,
              marginLeft: isMobile ? 0.5 : 0
            }
          }
        }>
          {loading && <Skeleton variant="rectangular" width={400} height={800}/>}
          {!loading && timesheet?.length > 0 &&
              <EmployeeListView
                  timesheet={timesheet}
                  timeOffs={timeOffs}
                  employee={employee}
                  publicHolidays={publicHolidays}/>}
        </Grid>

        {isMobile &&
            <Grid item xs={12} justifyContent="center" justifyItems="center">
              <ValidationConfirmationDialog
                  onSubmit={validate}
                  loading={isSubmitting}
                  disabled={loading || (timesheet?.length ?? 0) === 0 || hasPendingTimeOffs || isSubmitting}
                  isMobile={true}
              />
            </Grid>}

      </Grid>
  )
}
