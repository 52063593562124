import {
  collection,
  doc,
  FirestoreError,
  getDocs,
  updateDoc,
  limit,
  onSnapshot,
  orderBy,
  query,
  runTransaction,
  serverTimestamp,
  Unsubscribe,
  where
} from 'firebase/firestore'

import { db } from '../config/firebase'
import { Timesheet, timesheetConverter } from '../model/Timesheet'
import { TimeOff } from '../model/TimeOff'
import { PublicHoliday } from '../model/PublicHoliday'
import dayjs from 'dayjs'
import { employeesConverter } from '../model/Employee'

async function validateOrInvalidateTransaction (
  timesheet: Timesheet[] | undefined,
  timeOffs: TimeOff[],
  publicHolidays: PublicHoliday[],
  validate: boolean
) {
  await runTransaction(db, async (transaction) => {
    const employeeId = timesheet?.[0].employeeId
    const employeeRef = doc(collection(db, 'employees'), employeeId).withConverter(employeesConverter)
    const employeeSnap = await transaction.get(employeeRef)
    if (!employeeSnap.exists()) {
      throw new Error(`Employee ${employeeId ?? ''} not found`)
    }
    // getting all read data to be included into transaction
    for (const t of timesheet ?? []) {
      const docRef = doc(collection(db, 'timesheet'), t.id).withConverter(timesheetConverter)
      const docSnap = await transaction.get(docRef)
      if (!docSnap.exists()) {
        throw new Error('Timesheet not found')
      }
    }

    // updating all timesheet
    for (const t of timesheet ?? []) {
      const docRef = doc(collection(db, 'timesheet'), t.id).withConverter(timesheetConverter)

      if (validate) {
        const timeOff = timeOffs?.find((to) => dayjs(to.date).isSame(dayjs(t.date), 'day')) ?? null
        const publicHoliday = publicHolidays?.find((ph) => dayjs(ph.date).isSame(dayjs(t.date), 'day')) ?? null
        transaction.update(
          docRef,
          'validated', true,
          'validationDate', serverTimestamp(),
          'timeOff', timeOff,
          'publicHoliday', publicHoliday
        )
      } else {
        transaction.update(
          docRef,
          'validated', false,
          'validationDate', null,
          'timeOff', null,
          'publicHoliday', null
        )
      }
    }
    transaction.update(employeeRef, 'lastValidationDate', serverTimestamp())
  })
}

export const timesheetService = {
  onValidationsChanged (
    employeeId: string,
    onChange: (timesheet: Timesheet[]) => void,
    onError: (err: FirestoreError) => void
  ): Unsubscribe {
    return onSnapshot(
      query(
        collection(db, 'timesheet'),
        where('employeeId', '==', employeeId),
        where('validated', '==', false),
        where('date', '<=', new Date()),
        orderBy('date', 'asc')
      ).withConverter(timesheetConverter)
      , (querySnapshot) => {
        const validations: Timesheet[] = []
        querySnapshot.forEach((doc) => validations.push(doc.data()))
        onChange(validations)
      }, onError)
  },
  async validate (
    timesheet: Timesheet[] | undefined,
    timeOffs: TimeOff[],
    publicHolidays: PublicHoliday[]
  ) {
    await validateOrInvalidateTransaction(timesheet, timeOffs, publicHolidays, true)
  },
  async invalidate (
    timesheet: Timesheet[] | undefined,
    timeOffs: TimeOff[],
    publicHolidays: PublicHoliday[]
  ) {
    await validateOrInvalidateTransaction(timesheet, timeOffs, publicHolidays, false)
  },

  async invalidateFutureTimesheets(): Promise<void> {
    try {
      // Query to find all timesheets with a date in the future
      const futureTimesheetsQuery = query(
        collection(db, 'timesheet'),
        where('date', '>=', new Date()) // Filter for dates in the future
      )
      console.log('Timesheets retrieved')
      // Fetch the matching documents
      const querySnapshot = await getDocs(futureTimesheetsQuery)

      if (querySnapshot.empty) {
        console.log('No future timesheets found.')
        return
      }

      // Iterate through the results and update the `validated` field
      const updatePromises = querySnapshot.docs.map(async (docSnapshot) => {
        const docRef = doc(db, 'timesheet', docSnapshot.id)
        await updateDoc(docRef, { validated: false }) // Update the `validated` field
        console.log('Updating validated fields')
      })

      // Wait for all updates to complete
      await Promise.all(updatePromises)

      console.log('All future timesheets have been invalidated.')
    } catch (error) {
      console.error('Error invalidating future timesheets:', error)
      throw error // Rethrow to handle it in the calling code if necessary
    }
  },

  async retrieveLatestValidation (): Promise<Timesheet> {
    return await new Promise((resolve, reject) => {
      const retrieveLatestValidation = async () => {
        try {
          const docs = await getDocs(
            query(
              collection(db, 'timesheet'),
              orderBy('date', 'desc'),
              limit(1)
            )
              .withConverter(timesheetConverter))
          if (docs.empty) {
            reject(new Error('No timesheet found'))
            return
          }
          resolve(docs.docs[0].data())
        } catch (e) {
          reject(e)
        }
      }
      retrieveLatestValidation()
    })
  },
  async retrieveTimeSheetForEmployees (employeeIds: string[], startDate: Date, endDate: Date) {
    return await new Promise<Timesheet[]>((resolve, reject) => {
      const retrieveTimeSheetForEmployees = async () => {
        if (employeeIds?.length === 0 || !startDate || !endDate) {
          resolve([])
          return
        }
        try {
          const timesheet: Timesheet[] = []
          const chunkSize = 10
          for (let i = 0; i < employeeIds.length; i += chunkSize) {
            const employeeChunk = employeeIds.slice(i, i + chunkSize)
            const docs = await getDocs(
              query(
                collection(db, 'timesheet'),
                where('employeeId', 'in', employeeChunk),
                where('date', '>=', startDate),
                where('date', '<=', endDate)
              )
                .withConverter(timesheetConverter))
            docs.forEach((doc) => timesheet.push(doc.data()))
          }

          if (timesheet.length === 0) {
            resolve([])
            return
          }

          resolve(timesheet)
        } catch (e) {
          reject(e)
        }
      }
      retrieveTimeSheetForEmployees()
    })
  }
}
