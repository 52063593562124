import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot } from 'firebase/firestore'

export enum EmployeeStatus {
  FullTime = 'Full-Time',
  PartTime = 'Part-Time',
  Terminated = 'Terminated',
  Contractor = 'Contractor',
}

export interface Employee {
  id: string
  displayName: string
  firstName: string
  lastName: string
  jobTitle: string
  workEmail: string
  lastValidationDate: Date
  location: string
  division: string
  photoUrl: string
  employeeNumber: string
  employmentHistoryStatus: EmployeeStatus
  supervisor?: string
  supervisorEmail?: string
  terminationDate?: Date
}

export const employeesConverter:
FirestoreDataConverter<Employee> = {
  toFirestore: (employee: Employee): DocumentData => {
    return {
      id: employee.id || null,
      displayName: employee.displayName || null,
      firstName: employee.firstName || null,
      lastName: employee.lastName || null,
      jobTitle: employee.jobTitle || null,
      workEmail: employee.workEmail || null,
      lastValidationDate: employee.lastValidationDate || null,
      location: employee.location || null,
      division: employee.division || null,
      photoUrl: employee.photoUrl || null,
      employeeNumber: employee.employeeNumber || null,
      employmentHistoryStatus: employee.employmentHistoryStatus || null,
      supervisor: employee.supervisor ?? null,
      supervisorEmail: employee.supervisorEmail ?? null,
      terminationDate: employee.terminationDate ?? null
    }
  },

  fromFirestore: (
    snapshot: QueryDocumentSnapshot
  ): Employee => {
    const data = snapshot.data()

    return {
      id: data?.id,
      displayName: data?.displayName,
      firstName: data?.firstName,
      lastName: data?.lastName,
      jobTitle: data?.jobTitle,
      workEmail: data?.workEmail,
      lastValidationDate: data?.lastValidationDate?.toDate(),
      location: data?.location,
      division: data?.division,
      photoUrl: data?.photoUrl,
      employeeNumber: data?.employeeNumber,
      employmentHistoryStatus: data?.employmentHistoryStatus,
      supervisor: data?.supervisor,
      supervisorEmail: data?.supervisorEmail,
      terminationDate: data?.terminationDate
    }
  }
}
